<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">

        <b-tabs pills content-class="border-top" v-model="tabStep">
          <b-tab v-if="roleData.role_type !== 1" :active="roleData.role_type !== 1">
            <template #title>
              <span>General</span>
            </template>

            <div class="d-flex mt-2">
              <h4 class="mb-0 mt-0">General Information</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="8" lg="8">
                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="First Name" rules="required">
                      <b-form-group label="First Name">
                        <b-form-input trim placeholder="First Name" v-model="dataInfo.first_name" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Last Name" rules="required">
                      <b-form-group label="Last Name">
                        <b-form-input trim placeholder="Last Name" v-model="dataInfo.last_name" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Middle Initial">
                      <b-form-group label="Middle Initial">
                        <b-form-input trim placeholder="Middle Initial" v-model="dataInfo.middle_initial" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Graduation Year">
                      <b-form-group label="Graduation Year" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.graduation_year" :options="graduationYearOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Date of Birth">
                      <b-form-group label="Date of Birth">
                        <flat-pickr v-model="dataInfo.date_of_birth" class="form-control"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Email Address" rules="required">
                      <b-form-group label="Email Address">
                        <b-form-input trim placeholder="Last Name" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" md="4" lg="4">
                <div class="mt-5 d-flex justify-content-center align-content-end">
                  <b-avatar
                      ref="previewEl"
                      :src="(dataInfo.profile_picture_file ? dataInfo.profile_picture_file : getApiFile(dataInfo.profile_picture_url))"
                      :text="avatarText(dataInfo.first_name + ' ' + dataInfo.last_name)"
                      button
                      variant="primary"
                      size="150px"
                      @click="$refs.refInputEl.click()"/>
                  <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer">
                </div>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Acceptance Status</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="8" lg="8">

                <b-row>
                  <b-col cols="12" md="4" lg="4">
                    <validation-provider #default="validationContext" name="Accepted By A University?">
                      <b-form-group label="Accepted By A University?" :state="getValidationState(validationContext)">
                        <v-select v-model="dataInfo.is_accepted" :options="acceptedOptions" :reduce="val => val.value" :clearable="false"/>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Accepted By">
                      <b-form-group label="Accepted By">
                        <b-form-input trim placeholder="Accepted By" v-model="dataInfo.accepted_by" :state="getValidationState(validationContext)"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" md="6" lg="6">
                    <validation-provider #default="validationContext" name="Start Date">
                      <b-form-group label="Start Date">
                        <flat-pickr v-model="dataInfo.accepted_date" class="form-control"/>
                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Contact Information</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Preferred Method of Contact">
                  <b-form-group label="Preferred Method of Contact" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.preferred_method_of_contact" :options="preferredMethodContact" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Email Address">
                  <b-form-group label="Email Address">
                    <b-form-input trim placeholder="Email Address" v-model="dataInfo.email_address" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4"></b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Primary Phone">
                  <b-form-group label="Primary Phone">
                    <b-form-input trim placeholder="Primary Phone" v-model="dataInfo.primary_phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Primary Phone Type">
                  <b-form-group label="Primary Phone Type" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.primary_phone_type" :options="phoneType" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4"></b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Secondary Phone">
                  <b-form-group label="Secondary Phone">
                    <b-form-input trim placeholder="Secondary Phone" v-model="dataInfo.secondary_phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Secondary Phone Type">
                  <b-form-group label="Secondary Phone Type" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.secondary_phone_type" :options="phoneType" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-between align-items-center">
              <h4 class="mb-0 mt-0">Guardian Information</h4>
              <b-button @click="newGuardianShowModal" variant="primary" size="sm">
                <feather-icon icon="PlusCircleIcon" size="18"/>
                <span class="align-middle"></span>
              </b-button>
            </div>

            <b-row class="mt-1 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="dataInfo.guardians"
                  :fields="guardianTableColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

                <template #cell(relationship)="data">
                  <div class="text-nowrap">
                    <span v-if="data.value === 1">Mother</span>
                    <span v-if="data.value === 2">Father</span>
                    <span v-if="data.value === 3">Guardian</span>
                    <span v-if="data.value === 4">Grandmother</span>
                    <span v-if="data.value === 5">Grandfather</span>
                    <span v-if="data.value === 6">Other</span>
                  </div>
                </template>

              </b-table>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Address Information</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Street Address">
                  <b-form-group label="Street Address">
                    <b-form-input trim placeholder="Street Address" v-model="dataInfo.street_address" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="City">
                  <b-form-group label="City">
                    <b-form-input trim placeholder="City" v-model="dataInfo.city" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="State">
                  <b-form-group label="State" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.address_state_id" :options="states" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Zipcode">
                  <b-form-group label="Zipcode">
                    <b-form-input trim placeholder="Zipcode" v-model="dataInfo.zip_code" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Social Media</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Facebook Profile Link">
                  <b-form-group label="Facebook Profile Link">
                    <b-form-input trim placeholder="Facebook Profile Link" v-model="dataInfo.facebook_profile_link" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Twitter Profile Link">
                  <b-form-group label="Twitter Profile Link">
                    <b-form-input trim placeholder="Twitter Profile Link" v-model="dataInfo.twitter_profile_link" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Linkedin Profile Link">
                  <b-form-group label="Linkedin Profile Link">
                    <b-form-input trim placeholder="Linkedin Profile Link" v-model="dataInfo.linkedin_profile_link" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Field of Study</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Tell us your preferred field of study">
                  <b-form-group label="Tell us your preferred field of study">
                    <b-form-input trim placeholder="Tell us your preferred field of study" v-model="dataInfo.your_preferred_study" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Personal Statement</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Personal Statement">
                  <b-form-group label="Personal Statement">
                    <b-form-textarea trim placeholder="Your personal statement should tell the school about your goals and aspirations. What makes you a good candidate for their school and program? What makes you stand out from others applying?" v-model="dataInfo.personal_statement"
                                     :state="getValidationState(validationContext)" rows="5"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-modal v-model="newGuardianModalShow" title="Add Guardian" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleNewGuardianModal" centered no-close-on-backdrop v-if="newGuardianModalShow">
              <b-form-group label="* Relationship">
                <v-select v-model="newGuardianInfo.relationship" :options="relationshipOptions" :reduce="val => val.value" :clearable="false"/>
              </b-form-group>
              <b-form-group label="* First Name">
                <b-form-input trim placeholder="First Name" v-model="newGuardianInfo.first_name"/>
              </b-form-group>
              <b-form-group label="* Last Name">
                <b-form-input trim placeholder="Last Name" v-model="newGuardianInfo.last_name"/>
              </b-form-group>
              <b-form-group label="Email Address">
                <b-form-input trim placeholder="Email Address" v-model="newGuardianInfo.email"/>
              </b-form-group>
              <b-form-group label="Phone">
                <b-form-input trim placeholder="Phone" v-model="newGuardianInfo.phone"/>
              </b-form-group>
            </b-modal>

          </b-tab>
          <b-tab v-if="roleData.role_type !== 1">
            <template #title>
              <span>Academics</span>
            </template>

            <div class="d-flex mt-2">
              <h4 class="mb-0 mt-0">High School Information</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="State">
                  <b-form-group label="State" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.high_school_state_id" :options="states" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="High School Name">
                  <b-form-group label="High School Name">
                    <b-form-input trim placeholder="High School Name" v-model="dataInfo.high_school_name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Division">
                  <b-form-group label="Division" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.high_school_division" :options="division" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Conference">
                  <b-form-group label="Conference">
                    <b-form-input trim placeholder="Conference" v-model="dataInfo.high_school_conference" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-between align-items-center">
              <h4 class="mb-0 mt-0">Academic Files</h4>
              <b-button @click="newAcademicFileShowModal" variant="primary" size="sm">
                <feather-icon icon="PlusCircleIcon" size="18"/>
                <span class="align-middle"></span>
              </b-button>
            </div>

            <b-row class="mt-1 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="dataInfo.academic_files"
                  :fields="academicFilesTableColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

                <template #cell(document_url)="data">
                  <b-link class="font-weight-bold" target="_blank" :href="getApiFile(data.value)">View File</b-link>
                </template>

              </b-table>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Grades</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Cumulative GPA">
                  <b-form-group label="Cumulative GPA">
                    <b-form-input trim placeholder="Cumulative GPA" v-model="dataInfo.grades_cumulative_gpa" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Cumulative GPA (out of)">
                  <b-form-group label="Cumulative GPA (out of)">
                    <b-form-input trim placeholder="Cumulative GPA (out of)" v-model="dataInfo.grades_cumulative_gpa_out_of" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Core GPA">
                  <b-form-group label="Core GPA">
                    <b-form-input trim placeholder="Core GPA" v-model="dataInfo.grades_core_gpa" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Core GPA (out of)">
                  <b-form-group label="Core GPA (out of)">
                    <b-form-input trim placeholder="Core GPA (out of)" v-model="dataInfo.grades_core_gpa_out_of" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Weighted GPA">
                  <b-form-group label="Weighted GPA">
                    <b-form-input trim placeholder="Weighted GPA" v-model="dataInfo.grades_weighted_gpa" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Weighted GPA (out of)">
                  <b-form-group label="Weighted GPA (out of)">
                    <b-form-input trim placeholder="Weighted GPA (out of)" v-model="dataInfo.grades_weighted_gpa_out_of" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Class Rank</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Class Rank">
                  <b-form-group label="Class Rank">
                    <b-form-input trim placeholder="Class Rank" v-model="dataInfo.class_rank" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Class Rank (out of)">
                  <b-form-group label="Class Rank (out of)">
                    <b-form-input trim placeholder="Class Rank (out of)" v-model="dataInfo.class_rank_out_of" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Weighted Class Rank">
                  <b-form-group label="Weighted Class Rank">
                    <b-form-input trim placeholder="Weighted Class Rank" v-model="dataInfo.class_rank_weighted" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Weighted Class Rank (out of)">
                  <b-form-group label="Weighted Class Rank (out of)">
                    <b-form-input trim placeholder="Weighted Class Rank (out of)" v-model="dataInfo.class_rank_weighted_out_of" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">SAT</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Math">
                  <b-form-group label="Math">
                    <b-form-input trim placeholder="Math" v-model="dataInfo.sat_math" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Reading">
                  <b-form-group label="Reading">
                    <b-form-input trim placeholder="Reading" v-model="dataInfo.sat_reading" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Writing">
                  <b-form-group label="Writing">
                    <b-form-input trim placeholder="Writing" v-model="dataInfo.sat_writing" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="SAT Total Score">
                  <b-form-group label="SAT Total Score">
                    <b-form-input trim placeholder="SAT Total Score" v-model="dataInfo.sat_total_score" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="SAT II Total Score">
                  <b-form-group label="SAT II Total Score">
                    <b-form-input trim placeholder="SAT II Total Score" v-model="dataInfo.sat_two_total_score" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="PSAT/PLAN Total Score">
                  <b-form-group label="PSAT/PLAN Total Score">
                    <b-form-input trim placeholder="PSAT/PLAN Total Score" v-model="dataInfo.sat_psat_plan_total_score" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Notes">
                  <b-form-group label="Notes">
                    <b-form-input trim placeholder="Notes" v-model="dataInfo.sat_notes" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">ACT</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="ACT Total Score">
                  <b-form-group label="ACT Total Score">
                    <b-form-input trim placeholder="ACT Total Score" v-model="dataInfo.act_total_score" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="8" lg="8"></b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Notes">
                  <b-form-group label="Notes">
                    <b-form-input trim placeholder="Notes" v-model="dataInfo.act_notes" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Honors Classes</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Are you in honors classes?">
                  <b-form-group label="Are you in honors classes?" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.honors_classes" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">AP Classes</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Are you in AP classes?">
                  <b-form-group label="Are you in AP classes?" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.ap_classes" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Academic Accomplishments</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="List any academic achievements and/or awards">
                  <b-form-group label="List any academic achievements and/or awards">
                    <b-form-textarea trim placeholder="List any academic achievements and/or awards" v-model="dataInfo.academic_achievements_awards" :state="getValidationState(validationContext)" rows="5"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="List your extracurricular (non sports related) activities">
                  <b-form-group label="List your extracurricular (non sports related) activities">
                    <b-form-textarea trim placeholder="List your extracurricular (non sports related) activities" v-model="dataInfo.your_extracurricular" :state="getValidationState(validationContext)" rows="5"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-modal v-model="newAcademicFileModalShow" title="Add Academic File" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleNewAcademicFileModal" centered no-close-on-backdrop v-if="newAcademicFileModalShow">
              <b-form-group label="* Document Type">
                <b-form-input trim placeholder="Document Type" v-model="newAcademicFileInfo.document_type"/>
              </b-form-group>
              <b-form-group label="* Document">
                <b-form-file id="academic-file-document" plain accept=".jpg, .png, .gif, .pdf, .xls, .xlsx, .doc, .docx" @input="inputFileRenderer('academic-file-document', 'academic-file')"/>
              </b-form-group>
            </b-modal>

          </b-tab>
          <b-tab v-if="roleData.role_type !== 1">
            <template #title>
              <span>Documents</span>
            </template>

            <div class="d-flex justify-content-between align-items-center pt-1">
              <h4 class="mb-0 mt-0">Documents</h4>
              <b-button @click="newDocumentFileShowModal" variant="primary" size="sm">
                <feather-icon icon="PlusCircleIcon" size="18"/>
                <span class="align-middle"></span>
              </b-button>
            </div>

            <b-row class="mt-2 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="dataInfo.documents"
                  :fields="documentsColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

                <template #cell(document_url)="data">
                  <b-link class="font-weight-bold" target="_blank" :href="getApiFile(data.value)">View File</b-link>
                </template>

              </b-table>
            </b-row>

            <b-modal v-model="newDocumentFileModalShow" title="Add Document File" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleNewDocumentFileModal" centered no-close-on-backdrop v-if="newDocumentFileModalShow">
              <b-form-group label="* Document Name">
                <b-form-input trim placeholder="Document Name" v-model="newDocumentFileInfo.document_name"/>
              </b-form-group>
              <b-form-group label="* Document">
                <b-form-file id="document-file-document" plain accept=".jpg, .png, .gif, .pdf, .xls, .xlsx, .doc, .docx" @input="inputFileRenderer('document-file-document', 'document-file')"/>
              </b-form-group>
              <b-form-group label="Description">
                <b-form-input trim placeholder="Description" v-model="newDocumentFileInfo.short_description"/>
              </b-form-group>
            </b-modal>

          </b-tab>
          <b-tab v-if="roleData.role_type !== 1">
            <template #title>
              <span>Pictures</span>
            </template>

            <div class="d-flex justify-content-between align-items-center pt-1">
              <h4 class="mb-0 mt-0">Portfolio</h4>
              <b-button @click="newPortfolioFileShowModal" variant="primary" size="sm">
                <feather-icon icon="PlusCircleIcon" size="18"/>
                <span class="align-middle"></span>
              </b-button>
            </div>

            <b-row class="mt-2 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="dataInfo.portfolios"
                  :fields="documentsColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

                <template #cell(document_url)="data">
                  <b-link class="font-weight-bold" target="_blank" :href="getApiFile(data.value)">View File</b-link>
                </template>
              </b-table>
            </b-row>

            <b-modal v-model="newPortfolioFileModalShow" title="Add Portfolio File" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleNewPortfolioFileModal" centered no-close-on-backdrop v-if="newPortfolioFileModalShow">
              <b-form-group label="* Document Name">
                <b-form-input trim placeholder="Document Name" v-model="newPortfolioFileInfo.document_name"/>
              </b-form-group>
              <b-form-group label="* Document">
                <b-form-file id="portfolio-file-document" plain accept=".jpg, .png, .gif, .pdf, .xls, .xlsx, .doc, .docx" @input="inputFileRenderer('portfolio-file-document', 'portfolio-file')"/>
              </b-form-group>
              <b-form-group label="Description">
                <b-form-input trim placeholder="Description" v-model="newPortfolioFileInfo.short_description"/>
              </b-form-group>
            </b-modal>

          </b-tab>
          <b-tab v-if="roleData.role_type !== 1">
            <template #title>
              <span>Videos</span>
            </template>

            <div class="d-flex justify-content-between align-items-center pt-1">
              <h4 class="mb-0 mt-0">Videos</h4>
              <b-button @click="newVideoShowModal" variant="primary" size="sm">
                <feather-icon icon="PlusCircleIcon" size="18"/>
                <span class="align-middle"></span>
              </b-button>
            </div>

            <b-row class="mt-2 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="dataInfo.videos"
                  :fields="videosColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>
              </b-table>
            </b-row>

            <b-modal v-model="newVideoModalShow" title="Add Video" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleNewVideoModal" centered no-close-on-backdrop v-if="newVideoModalShow">
              <b-form-group label="* Video Website Url">
                <b-form-input trim placeholder="Video Website Url" v-model="newVideoInfo.video_website_url"/>
              </b-form-group>
            </b-modal>

          </b-tab>
          <b-tab :active="roleData.role_type === 1">
            <template #title>
              <span>Athletics</span>
            </template>

            <div class="d-flex mt-2">
              <h4 class="mb-0 mt-0">Profile</h4>
            </div>

            <b-row class="mt-1">
              <b-col cols="12" md="3" lg="3">
                <validation-provider #default="validationContext" name="Profile Type">
                  <b-form-group label="Profile Type" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.profile_type_id" :options="sports" :reduce="val => val.value" :clearable="false" v-on:input="getSport"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex" v-if="sport_infos.length > 0">
              <h4 class="mb-0 mt-0">Athletics Information</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4" v-for="(item, index) in sport_infos" :key="index">
                <validation-provider #default="validationContext" :name="`${item.name}`">
                  <b-form-group :label="`${item.name}`">
                    <b-form-input trim :placeholder="`${item.name}`" v-model="item.value" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex" v-if="sport_stats.length > 0">
              <h4 class="mb-0 mt-0">Stats</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4" v-for="(item, index) in sport_stats" :key="index">
                <validation-provider #default="validationContext" :name="`${item.name}`">
                  <b-form-group :label="`${item.name}`">
                    <b-form-input trim :placeholder="`${item.name}`" v-model="item.value" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row class="mt-1 pt-2 mb-1 border-top" v-if="dataInfo.profile_type_id">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Year">
                  <b-form-group label="Year">
                    <b-form-input trim placeholder="Year" v-model="sport_additional.year" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="School/Club">
                  <b-form-group label="School/Club">
                    <b-form-input trim placeholder="School/Club" v-model="sport_additional.school_club" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Document">
                  <b-form-group label="Document">
                    <b-form-file id="sport-additional-document-file" plain accept=".jpg, .png, .gif, .pdf, .xls, .xlsx, .doc, .docx" @input="inputFileRenderer('sport-additional-document-file', 'sport-additional')"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Statistics">
                  <b-form-group label="Statistics">
                    <b-form-textarea trim placeholder="Statistics" v-model="sport_additional.statistics" :state="getValidationState(validationContext)" rows="5"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12" v-if="roleData.role_type !== 1">
                <div class="d-flex justify-content-end align-items-center pt-1">
                  <b-button @click="addSportAdditional" variant="primary" size="md">
                    <feather-icon icon="PlusIcon" size="18"/>
                    <span class="align-middle"> Add</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-row class="mt-2 mb-1" v-if="dataInfo.profile_type_id">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="sport_adds"
                  :fields="sportAddColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <b-link class="font-weight-bold" target="_blank" :href="getApiFile(data.item.document_url)">View File</b-link>
                  </div>
                </template>

              </b-table>
            </b-row>

            <b-row class="mt-1 mb-1" v-if="dataInfo.profile_type_id && roleData.role_type !== 1">
              <b-col cols="12" md="12" lg="12">
                <div class="d-flex justify-content-center align-items-center pt-1">
                  <b-button @click="saveProfileType" variant="primary" size="md">
                    <feather-icon icon="PlusIcon" size="18"/>
                    <span class="align-middle"> Add Profile Type</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-row class="mt-2 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="dataInfo.profile_types"
                  :fields="profileTypeColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="editProfileType(data.item)" class="mx-1"/>
                  </div>
                </template>

              </b-table>
            </b-row>

            <div class="d-none">
              <b-button variant="primary" class="mb-2 btn-sm-block">
                Add Profile Type
              </b-button>
            </div>

            <div class="d-flex border-top pt-2">
              <h4 class="mb-0 mt-0">High School Coach</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Name">
                  <b-form-group label="Name">
                    <b-form-input trim placeholder="Name" v-model="dataInfo.high_school_coach_name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Phone">
                  <b-form-group label="Phone">
                    <b-form-input trim placeholder="Phone" v-model="dataInfo.high_school_coach_phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Email">
                  <b-form-group label="Email">
                    <b-form-input trim placeholder="Email" v-model="dataInfo.high_school_coach_email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Comments">
                  <b-form-group label="Comments">
                    <b-form-input trim placeholder="Comments" v-model="dataInfo.high_school_coach_comments" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Travel Coach</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Name">
                  <b-form-group label="Name">
                    <b-form-input trim placeholder="Name" v-model="dataInfo.travel_coach_name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Phone">
                  <b-form-group label="Phone">
                    <b-form-input trim placeholder="Phone" v-model="dataInfo.travel_coach_phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Email">
                  <b-form-group label="Email">
                    <b-form-input trim placeholder="Email" v-model="dataInfo.travel_coach_email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <validation-provider #default="validationContext" name="Comments">
                  <b-form-group label="Comments">
                    <b-form-input trim placeholder="Comments" v-model="dataInfo.travel_coach_comments" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Additional Information</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Honors and Awards">
                  <b-form-group label="Honors and Awards">
                    <b-form-input trim placeholder="Honors and Awards" v-model="dataInfo.honors_and_awards" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Additional Notes">
                  <b-form-group label="Additional Notes">
                    <b-form-input trim placeholder="Additional Notes" v-model="dataInfo.additional_notes" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Family Athletic History">
                  <b-form-group label="Family Athletic History">
                    <b-form-input trim placeholder="Family Athletic History" v-model="dataInfo.family_athletic_history" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Camps Attended">
                  <b-form-group label="Camps Attended">
                    <b-form-input trim placeholder="Camps Attended" v-model="dataInfo.camps_attended" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4" lg="4">
                <validation-provider #default="validationContext" name="Community Service">
                  <b-form-group label="Community Service">
                    <b-form-input trim placeholder="Community Service" v-model="dataInfo.community_service" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Additional Sports</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Sports 2 Name">
                  <b-form-group label="Sports 2 Name">
                    <b-form-input trim placeholder="Sports 2 Name" v-model="dataInfo.sports_two_name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Sports 2 Stats">
                  <b-form-group label="Sports 2 Stats">
                    <b-form-input trim placeholder="Sports 2 Stats" v-model="dataInfo.sports_two_stats" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Sports 3 Name">
                  <b-form-group label="Sports 3 Name">
                    <b-form-input trim placeholder="Sports 3 Name" v-model="dataInfo.sports_three_name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Sports 3 Stats">
                  <b-form-group label="Sports 3 Stats">
                    <b-form-input trim placeholder="Sports 3 Stats" v-model="dataInfo.sports_three_stats" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex">
              <h4 class="mb-0 mt-0">Other Information</h4>
            </div>

            <b-row class="mt-1 mb-1">
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Graduation Year">
                  <b-form-group label="Graduation Year">
                    <b-form-input trim placeholder="Graduation Year" v-model="dataInfo.other_graduation_year" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="NCAA Eligibility Number">
                  <b-form-group label="NCAA Eligibility Number">
                    <b-form-input trim placeholder="NCAA Eligibility Number" v-model="dataInfo.ncaa_eligibility_number" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Height">
                  <b-form-group label="Height">
                    <b-form-input trim placeholder="Height" v-model="dataInfo.height" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Weight">
                  <b-form-group label="Weight">
                    <b-form-input trim placeholder="Weight" v-model="dataInfo.weight" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Parent/Guardian Name">
                  <b-form-group label="Parent/Guardian Name">
                    <b-form-input trim placeholder="Parent/Guardian Name" v-model="dataInfo.parent_guardian_name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Parent/Guardian Phone">
                  <b-form-group label="Parent/Guardian Phone">
                    <b-form-input trim placeholder="Parent/Guardian Phone" v-model="dataInfo.parent_guardian_phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Parent/Guardian Email">
                  <b-form-group label="Parent/Guardian Email">
                    <b-form-input trim placeholder="Parent/Guardian Email" v-model="dataInfo.parent_guardian_email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Student Athlete Phone">
                  <b-form-group label="Student Athlete Phone">
                    <b-form-input trim placeholder="Student Athlete Phone" v-model="dataInfo.student_athlete_phone" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Student Athlete Email">
                  <b-form-group label="Student Athlete Email">
                    <b-form-input trim placeholder="Student Athlete Email" v-model="dataInfo.student_athlete_email" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Facebook Link">
                  <b-form-group label="Facebook Link">
                    <b-form-input trim placeholder="Facebook Link" v-model="dataInfo.other_facebook_link" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <validation-provider #default="validationContext" name="Recruiting Specialist">
                  <b-form-group label="Recruiting Specialist">
                    <b-form-input trim placeholder="Recruiting Specialist" v-model="dataInfo.recruiting_specialist" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

          </b-tab>
          <b-tab v-if="roleData.role_type !== 1">
            <template #title>
              <span>Extracurricular</span>
            </template>

            <div class="d-flex justify-content-between align-items-center pt-1">
              <h4 class="mb-0 mt-0">Extracurricular</h4>
              <b-button @click="newExtracurricularShowModal" variant="primary" size="sm">
                <feather-icon icon="PlusCircleIcon" size="18"/>
                <span class="align-middle"></span>
              </b-button>
            </div>

            <b-row class="mt-2 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="dataInfo.extra_curricular"
                  :fields="extraCurricularColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

              </b-table>
            </b-row>

            <b-modal v-model="newExtracurricularModalShow" title="Add Extracurricular" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleNewExtracurricularModal" centered no-close-on-backdrop v-if="newExtracurricularModalShow">
              <b-form-group label="* Club Name">
                <b-form-input trim placeholder="Club Name" v-model="newExtracurricularInfo.club_name"/>
              </b-form-group>
              <b-form-group label="* Club Adviser">
                <b-form-input trim placeholder="Club Adviser" v-model="newExtracurricularInfo.club_adviser"/>
              </b-form-group>
              <b-form-group label="* Role">
                <b-form-input trim placeholder="Role" v-model="newExtracurricularInfo.role"/>
              </b-form-group>
              <b-form-group label="Description">
                <b-form-input trim placeholder="Description" v-model="newExtracurricularInfo.description"/>
              </b-form-group>
            </b-modal>

          </b-tab>
          <b-tab v-if="$router.currentRoute.params.id > 0 && roleData.role_type !== 1">
            <template #title>
              <span>Matched Universities</span>
            </template>

            <div class="d-flex justify-content-between align-items-center pt-1">
              <h4 class="mb-0 mt-0">Matched Universities</h4>
              <div>
                <b-button @click="newUniversityDepartmentShowModal" variant="primary" class="mr-1">
                  <span class="align-middle">New Record</span>
                </b-button>
                <b-button @click="removeSelectedRecords" variant="danger" class="mr-1">
                  <span class="align-middle">Remove Selected Records</span>
                </b-button>
                <b-button @click="sendEmailSelectedRecords" variant="success">
                  <span class="align-middle">Re-Send Email To Universities</span>
                </b-button>
              </div>
            </div>

            <b-row class="mt-2 mb-1">
              <b-table
                  ref="refListTable"
                  primary-key="id"
                  empty-text="No record found"
                  class="position-relative"
                  :items="matchedUniversities"
                  :fields="matchedColumns"
                  responsive striped bordered hover show-empty>

                <template #cell(selection)="data">
                  <b-form-checkbox :checked="data.value" @change="changeSelectValue(data.item)"/>
                </template>

                <template #cell(id)="data">
                  <b-link class="font-weight-bold">#{{ data.value }}</b-link>
                </template>

                <template #cell(status)="data">
                  <active-column-table :data="data.value"></active-column-table>
                </template>

                <template #cell(is_academy)="data">
                  <boolean-column-table :data="data.value"></boolean-column-table>
                </template>

                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <remove-button-table :data="data" :re-fetch-data="getDetail" remove-url="removeUniversityDepartment"></remove-button-table>
                  </div>
                </template>

              </b-table>
            </b-row>

            <b-modal v-model="newUniversityDepartmentModalShow" title="Add University Department" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleUniversityDepartmentModal" centered no-close-on-backdrop v-if="newUniversityDepartmentModalShow">
              <b-form-group label="* University">
                <v-select v-model="newUniversityDepartment.university_id" :options="universityOptions" :reduce="val => val.value" :clearable="false" v-on:input="getUniversityDepartments"/>
              </b-form-group>
              <b-form-group label="* Department">
                <v-select v-model="newUniversityDepartment.university_department_id" :options="universityDepartmentOptions" :reduce="val => val.value" :clearable="false"/>
              </b-form-group>
            </b-modal>

          </b-tab>
        </b-tabs>

        <action-buttons :back-route="'student-list'" v-if="roleData.role_type !== 1"/>

      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTab, BMediaAside, BTabs, BMediaBody, BMedia, BImg, BFormFile, BFormTextarea, BCardText, BTable, BLink, BDropdownItem, BDropdown, BBadge, BButtonGroup, BButtonToolbar, BTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/student/store"
import universityModule from "@/views/university/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import {ActiveColumnTable, BooleanColumnTable, DateTimeColumnTable} from "@/components/Table";
import RemoveButtonTable from "@/components/Table/RemoveButtonTable.vue";
import FlatPickr from "vue-flatpickr-component/src/component";
import {useInputImageRenderer, useInputIdImageRenderer} from "@core/comp-functions/forms/form-utils";
import Vue from "vue";

export default {
  components: {
    BFormCheckbox,
    BTooltip,
    ActiveColumnTable, BooleanColumnTable,
    FlatPickr,
    RemoveButtonTable,
    BButtonToolbar, BButtonGroup,
    BBadge, BDropdown, DateTimeColumnTable, BDropdownItem, BLink, BTable,
    BCardText, BFormTextarea, BFormFile, BImg, BMedia, BMediaBody, BTabs, BMediaAside, BTab,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_UNIVERSITY_MODULE_NAME = 'store-university'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_UNIVERSITY_MODULE_NAME, universityModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_UNIVERSITY_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      profile_picture_file: null,
      profile_picture_url: null,
      guardians: [],
      academic_files: [],
      documents: [],
      portfolios: [],
      videos: [],
      extra_curricular: [],
      profile_types: [],
    })

    const sport_infos = ref([])
    const sport_stats = ref([])
    const sport_adds = ref([])

    const matchedUniversities = ref([])
    const sport_additional = ref({
      index: null,
      year: '',
      school_club: '',
      statistics: '',
      document_file: null,
      document_url: '',
    })

    const universityOptions = ref([])
    const universityDepartmentOptions = ref([])

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        tabStep.value += 1
        if (tabStep.value > 7) {
          router.push({name: 'student-list'})
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    const graduationYearOptions = [
      {label: '2020', value: 2020},
      {label: '2021', value: 2021},
      {label: '2022', value: 2022},
      {label: '2023', value: 2023},
      {label: '2024', value: 2024},
      {label: '2025', value: 2025},
    ]

    const acceptedOptions = [
      {label: 'No', value: 0},
      {label: 'Yes', value: 1},
    ]

    const preferredMethodContact = [
      {label: 'Phone', value: 1},
      {label: 'Email', value: 2},
      {label: 'Text', value: 3},
    ]

    const phoneType = [
      {label: 'Home', value: 1},
      {label: 'My Mobile', value: 2},
      {label: 'Mom\'s Mobile', value: 3},
      {label: 'Dad\'s Mobile', value: 4},
      {label: 'Guadian\'s Mobile', value: 5},
      {label: 'Mom\'s Work', value: 6},
      {label: 'Dad\'s Work', value: 7},
      {label: 'Guadian\'s Work', value: 8},
      {label: 'Other', value: 9},
    ]

    const states = [
      {label: 'Alaska', value: 1},
      {label: 'Alabama', value: 2},
      {label: 'Arkansas', value: 3},
      {label: 'Arizona', value: 4},
      {label: 'California', value: 5},
      {label: 'Colorado', value: 6},
      {label: 'Connecticut', value: 7},
      {label: 'District of Columbia', value: 8},
      {label: 'Delaware', value: 9},
      {label: 'Florida', value: 10},
      {label: 'Georgia', value: 11},
      {label: 'Hawaii', value: 12},
      {label: 'Iowa', value: 13},
      {label: 'Idaho', value: 14},
      {label: 'Illinois', value: 15},
      {label: 'Indiana', value: 16},
      {label: 'Kansas', value: 17},
      {label: 'Kentucky', value: 18},
      {label: 'Louisiana', value: 19},
      {label: 'Massachusetts', value: 20},
      {label: 'Maryland', value: 21},
      {label: 'Maine', value: 22},
      {label: 'Michigan', value: 23},
      {label: 'Minnesota', value: 24},
      {label: 'Missouri', value: 25},
      {label: 'Mississippi', value: 26},
      {label: 'Montana', value: 27},
      {label: 'North Carolina', value: 28},
      {label: 'North Dakota', value: 29},
      {label: 'Nebraska', value: 30},
      {label: 'New Hampshire', value: 31},
      {label: 'New Jersey', value: 32},
      {label: 'New Mexico', value: 33},
      {label: 'Nevada', value: 34},
      {label: 'New York', value: 35},
      {label: 'Ohio', value: 36},
      {label: 'Oklahoma', value: 37},
      {label: 'Oregon', value: 38},
      {label: 'Pennsylvania', value: 39},
      {label: 'Rhode Island', value: 40},
      {label: 'South Carolina', value: 41},
      {label: 'South Dakota', value: 42},
      {label: 'Tennessee', value: 43},
      {label: 'Texas', value: 44},
      {label: 'Utah', value: 45},
      {label: 'Virginia', value: 46},
      {label: 'Vermont', value: 47},
      {label: 'Washington', value: 48},
      {label: 'Wisconsin', value: 49},
      {label: 'West Virginia', value: 50},
      {label: 'Wyoming', value: 51}
    ];

    const division = [
      {label: '1A', value: 1},
      {label: '2A', value: 2},
      {label: '3A', value: 3},
      {label: '4A', value: 4},
      {label: '5A', value: 5},
      {label: '6A', value: 6},
      {label: '7A', value: 7},
      {label: '8A', value: 8},
      {label: 'Class A', value: 9},
      {label: 'Class B', value: 10},
      {label: 'Class C', value: 11},
      {label: 'Class D', value: 12},
      {label: 'Other', value: 13}
    ];

    const guardianTableColumns = [
      {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
      {key: 'relationship', label: 'RELATIONSHIP', sortable: true},
      {key: 'first_name', label: 'FIRST NAME', sortable: true},
      {key: 'last_name', label: 'LAST NAME', sortable: true},
      {key: 'email_address', label: 'EMAIL ADDRESS', sortable: true},
      {key: 'phone', label: 'PHONE', sortable: true},
    ]

    const academicFilesTableColumns = [
      {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
      {key: 'document_type', label: 'DOCUMENT TYPE', sortable: true},
      {key: 'document_url', label: 'DOCUMENT', sortable: true},
    ]

    const documentsColumns = [
      {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
      {key: 'document_name', label: 'DOCUMENT NAME', sortable: true},
      {key: 'document_url', label: 'DOCUMENT', sortable: true},
      {key: 'short_description', label: 'DESCRIPTION', sortable: true},
    ]

    const videosColumns = [
      {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
      {key: 'video_website_url', label: 'VIDEO/WEBSITE URL', sortable: true},
    ]

    const profileTypeColumns = [
      {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
      {key: 'profile_type_name', label: 'PROFILE TYPE', sortable: true},
      {key: 'actions', label: 'PREVIEW', sortable: true},
    ]

    const extraCurricularColumns = [
      {key: 'id', label: '#', sortable: true, thStyle: {width: "1%"}},
      {key: 'club_name', label: 'CLUB NAME', sortable: true},
      {key: 'club_adviser', label: 'CLUB ADVISOR', sortable: true},
      {key: 'role', label: 'ROLE', sortable: true},
      {key: 'description', label: 'DESCRIPTION', sortable: true},
    ]

    const sportAddColumns = [
      {key: 'year', label: 'YEAR', sortable: true},
      {key: 'school_club', label: 'SCHOOL/CLUB', sortable: true},
      {key: 'statistics', label: 'STATISTICS', sortable: true},
      {key: 'actions', label: 'ACTIONS', sortable: true},
    ]

    const sports = ref([])
    busy.value = true
    store.dispatch('store/sportItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          sports.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-university/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.status === 1) {
          universityOptions.value.push({label: value.name, value: value.id})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const getSport = (val) => {
      busy.value = true
      store.dispatch('store/sportItem', {id: val, student_id: router.currentRoute.params.id}).then(response => {
        sport_stats.value = response.data.data.stats_field_list
        sport_infos.value = response.data.data.info_field_list
        sport_adds.value = []
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    const matchedColumns = [
      {key: 'id', label: '#', sortable: false, thStyle: {width: "1%"}},
      {key: 'selection', label: 'SELECT', thStyle: {width: "1%"}},
      {key: 'university_name', label: 'UNIVERSITY', sortable: false},
      {key: 'first_name', label: 'FIRST NAME', sortable: false},
      {key: 'last_name', label: 'LAST NAME', sortable: false},
      {key: 'email', label: 'EMAIL', sortable: false},
      {key: 'phone', label: 'PHONE', sortable: false},
      {key: 'is_academy', label: 'IS ACADEMY', sortable: false},
      {key: 'sport_name', label: 'SPORT NAME', sortable: false},
      {key: 'actions', label: 'ACTIONS', sortable: false, thStyle: {width: "1%"}},
    ]

    const getDetail = () => {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
        if (error.response.status === 403) {
          router.push({name: 'student-list'})
        }
      }).finally(message => {
        busy.value = false
      })

      busy.value = true
      store.dispatch('store/matchedUniversities', {id: router.currentRoute.params.id}).then(response => {
        matchedUniversities.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
      }).finally(message => {
        busy.value = false
      })
    }

    if (router.currentRoute.params.id > 0) {
      getDetail()
    }

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.profile_picture_file = base64
    })


    const editProfileType = (item) => {
      dataInfo.value.profile_types.forEach((value, index) => {
        if (value === item) {
          dataInfo.value.profile_type_id = item.profile_type_id
          sport_infos.value = item.sport_infos
          sport_stats.value = item.sport_stats
          sport_adds.value = item.sport_adds
        }
      })
    }

    const tabStep = ref(0)

    //New Guardian
    const newGuardianInfo = ref({
      student_id: router.currentRoute.params.id,
      relationship: null,
      first_name: '',
      last_name: '',
      email_address: '',
      phone: ''
    })
    const relationshipOptions = [
      {label: 'Mother', value: 1},
      {label: 'Father', value: 2},
      {label: 'Guardian', value: 3},
      {label: 'Grandmother', value: 4},
      {label: 'Grandfather', value: 5},
      {label: 'Other', value: 6},
    ]
    const newGuardianModalShow = ref(false)
    const newGuardianShowModal = () => {
      newGuardianInfo.value = {
        student_id: router.currentRoute.params.id,
        relationship: null,
        first_name: '',
        last_name: '',
        email_address: '',
        phone: ''
      }
      newGuardianModalShow.value = true
    }
    const handleNewGuardianModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (newGuardianInfo.value.relationship === null) {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Relationship is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newGuardianInfo.value.first_name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'First Name is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newGuardianInfo.value.last_name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Last Name is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitNewGuardianModal()
    }
    const submitNewGuardianModal = () => {
      busy.value = true
      store.dispatch('store/saveGuardian', newGuardianInfo.value).then(response => {
        newGuardianModalShow.value = false
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    //New Academic File
    const newAcademicFileInfo = ref({
      student_id: router.currentRoute.params.id,
      document_type: '',
      document_file: '',
    })
    const newAcademicFileModalShow = ref(false)
    const newAcademicFileShowModal = () => {
      newAcademicFileInfo.value = {
        student_id: router.currentRoute.params.id,
        document_type: '',
        document_file: '',
      }
      newAcademicFileModalShow.value = true
    }
    const handleNewAcademicFileModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (newAcademicFileInfo.value.document_type === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Document Type is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newAcademicFileInfo.value.document_file === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Document File is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitNewAcademicFileModal()
    }
    const submitNewAcademicFileModal = () => {
      busy.value = true
      store.dispatch('store/saveAcademicFile', newAcademicFileInfo.value).then(response => {
        newAcademicFileModalShow.value = false
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    //New Document File
    const newDocumentFileInfo = ref({
      student_id: router.currentRoute.params.id,
      document_name: '',
      document_file: '',
      short_description: '',
    })
    const newDocumentFileModalShow = ref(false)
    const newDocumentFileShowModal = () => {
      newDocumentFileInfo.value = {
        student_id: router.currentRoute.params.id,
        document_name: '',
        document_file: '',
        short_description: '',
      }
      newDocumentFileModalShow.value = true
    }
    const handleNewDocumentFileModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (newDocumentFileInfo.value.document_name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Document Name is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newDocumentFileInfo.value.document_file === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Document File is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitNewDocumentFileModal()
    }
    const submitNewDocumentFileModal = () => {
      busy.value = true
      store.dispatch('store/saveDocumentFile', newDocumentFileInfo.value).then(response => {
        newDocumentFileModalShow.value = false
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    //New Portfolio File
    const newPortfolioFileInfo = ref({
      student_id: router.currentRoute.params.id,
      document_name: '',
      document_file: '',
      short_description: '',
    })
    const newPortfolioFileModalShow = ref(false)
    const newPortfolioFileShowModal = () => {
      newPortfolioFileInfo.value = {
        student_id: router.currentRoute.params.id,
        document_name: '',
        document_file: '',
        short_description: '',
      }
      newPortfolioFileModalShow.value = true
    }
    const handleNewPortfolioFileModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (newPortfolioFileInfo.value.document_name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Document Name is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newPortfolioFileInfo.value.document_file === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Document File is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitNewPortfolioFileModal()
    }
    const submitNewPortfolioFileModal = () => {
      busy.value = true
      store.dispatch('store/savePortfolioFile', newPortfolioFileInfo.value).then(response => {
        newPortfolioFileModalShow.value = false
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    const inputFileRenderer = (input, type) => {
      useInputIdImageRenderer(input, base64 => {
        if (type === 'academic-file') {
          newAcademicFileInfo.value.document_file = base64
        } else if (type === 'document-file') {
          newDocumentFileInfo.value.document_file = base64
        } else if (type === 'portfolio-file') {
          newPortfolioFileInfo.value.document_file = base64
        } else if (type === 'sport-additional') {
          sport_additional.value.document_file = base64
        }
      })
    }

    //New Video File
    const newVideoInfo = ref({
      student_id: router.currentRoute.params.id,
      video_website_url: '',
    })
    const newVideoModalShow = ref(false)
    const newVideoShowModal = () => {
      newVideoInfo.value = {
        student_id: router.currentRoute.params.id,
        video_website_url: '',
      }
      newVideoModalShow.value = true
    }
    const handleNewVideoModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (newVideoInfo.value.video_website_url === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Video WebSite Url is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      submitNewVideoModal()
    }
    const submitNewVideoModal = () => {
      busy.value = true
      store.dispatch('store/saveVideos', newVideoInfo.value).then(response => {
        newVideoModalShow.value = false
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    //New Extracurricular File
    const newExtracurricularInfo = ref({
      student_id: router.currentRoute.params.id,
      club_name: '',
      club_adviser: '',
      role: '',
      description: ''
    })
    const newExtracurricularModalShow = ref(false)
    const newExtracurricularShowModal = () => {
      newExtracurricularInfo.value = {
        student_id: router.currentRoute.params.id,
        club_name: '',
        club_adviser: '',
        role: '',
        description: ''
      }
      newExtracurricularModalShow.value = true
    }
    const handleNewExtracurricularModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (newExtracurricularInfo.value.club_name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Club Name is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newExtracurricularInfo.value.club_adviser === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Club Adviser is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newExtracurricularInfo.value.role === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Role is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      submitNewExtracurricularModal()
    }
    const submitNewExtracurricularModal = () => {
      busy.value = true
      store.dispatch('store/saveExtraCurricular', newExtracurricularInfo.value).then(response => {
        newExtracurricularModalShow.value = false
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    const saveProfileType = () => {
      busy.value = true
      store.dispatch('store/saveProfileType', {
        student_id: router.currentRoute.params.id,
        profile_type_id: dataInfo.value.profile_type_id,
        sport_infos: sport_infos.value,
        sport_stats: sport_stats.value,
        sport_adds: sport_adds.value,
      }).then(response => {
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    const addSportAdditional = () => {
      if (sport_additional.value.year !== '' && sport_additional.value.school_club !== '') {
        sport_adds.value.push({
          index: null,
          year: sport_additional.value.year,
          school_club: sport_additional.value.school_club,
          statistics: sport_additional.value.statistics,
          document_file: sport_additional.value.document_file,
        })

        sport_additional.value = {
          index: null,
          year: '',
          school_club: '',
          statistics: '',
          document_file: null,
        }
      } else {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Required all fields!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    const newUniversityDepartment = ref({
      student_id: router.currentRoute.params.id,
      university_id: null,
      university_department_id: null,
    })
    const newUniversityDepartmentModalShow = ref(false)
    const newUniversityDepartmentShowModal = () => {
      newUniversityDepartment.value = {
        student_id: router.currentRoute.params.id,
        university_id: null,
        university_department_id: null,
      }
      newUniversityDepartmentModalShow.value = true
    }
    const handleUniversityDepartmentModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (newUniversityDepartment.value.university_id === null) {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'University is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (newUniversityDepartment.value.university_department_id === null) {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'University Department is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      submitUniversityDepartmentModal()
    }
    const submitUniversityDepartmentModal = () => {
      busy.value = true
      store.dispatch('store/saveUniversityDepartment', newUniversityDepartment.value).then(response => {
        newUniversityDepartmentModalShow.value = false
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Record saved.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        getDetail()
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })
    }

    const getUniversityDepartments = (val) => {
      universityDepartmentOptions.value = []
      busy.value = true
      store.dispatch('store-university/fetchDepartmentList', {universityId: val}).then(response => {
        response.data.data.forEach((value, index) => {
          universityDepartmentOptions.value.push({label: value.first_name + ' ' + value.last_name + ' - ' + (value.sports === null || value.sports === '' ? 'Academy' : value.sports), value: value.id})
        });
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const changeSelectValue = (target) => {
      matchedUniversities.value.forEach(function callback(value, index) {
        if (value.id === target.id) {
          value.selection = !target.selection
        }
      })
    }


    const removeSelectedRecords = () => {
      Vue.swal({
        title: 'Delete?',
        text: 'Selected record is deleted!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let idList = [];
          matchedUniversities.value.forEach(function callback(value, index) {
            if (value.selection === true) {
              idList.push(value.id)
            }
          })

          if (idList.length === 0) {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: 'Please select record.',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })

            return
          }

          store.dispatch('store/removeUniversityDepartmentList', {dataInfo: idList}).then(response => {
            getDetail()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const sendEmailSelectedRecords = () => {
      Vue.swal({
        title: 'Send e-Mail?',
        text: 'Re-send email to universities',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Send',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/emailUniversityDepartmentList', {student_id: router.currentRoute.params.id}).then(response => {
            Vue.swal({
              icon: 'success',
              title: 'OK!',
              text: 'E-Mail sent.',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const roleData = JSON.parse(localStorage.getItem('roleData'))

    return {
      busy,
      dataInfo,
      refFormObserver,

      graduationYearOptions,
      preferredMethodContact,
      phoneType,
      acceptedOptions,
      states,
      division,
      guardianTableColumns,
      academicFilesTableColumns,
      documentsColumns,
      videosColumns,
      extraCurricularColumns,
      sports,
      matchedColumns,
      profileTypeColumns,
      matchedUniversities,
      previewEl,
      refInputEl,
      roleData,

      sport_infos,
      sport_stats,
      tabStep,

      newGuardianInfo,
      newGuardianModalShow,
      relationshipOptions,
      newGuardianShowModal,
      handleNewGuardianModal,
      submitNewGuardianModal,

      newAcademicFileInfo,
      newAcademicFileModalShow,
      newAcademicFileShowModal,
      handleNewAcademicFileModal,
      submitNewAcademicFileModal,

      newDocumentFileInfo,
      newDocumentFileModalShow,
      newDocumentFileShowModal,
      handleNewDocumentFileModal,
      submitNewDocumentFileModal,

      newPortfolioFileInfo,
      newPortfolioFileModalShow,
      newPortfolioFileShowModal,
      handleNewPortfolioFileModal,
      submitNewPortfolioFileModal,

      newVideoInfo,
      newVideoModalShow,
      newVideoShowModal,
      handleNewVideoModal,
      submitNewVideoModal,

      newExtracurricularInfo,
      newExtracurricularModalShow,
      newExtracurricularShowModal,
      handleNewExtracurricularModal,
      submitNewExtracurricularModal,

      saveProfileType,

      editProfileType,
      getSport,
      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      inputImageRenderer,
      inputFileRenderer,

      yesNoOptions,

      addSportAdditional,
      sport_additional,
      sport_adds,
      sportAddColumns,

      newUniversityDepartment,
      newUniversityDepartmentModalShow,
      universityOptions,
      universityDepartmentOptions,

      newUniversityDepartmentShowModal,
      handleUniversityDepartmentModal,
      submitUniversityDepartmentModal,
      getUniversityDepartments,
      getDetail,
      changeSelectValue,
      removeSelectedRecords,
      sendEmailSelectedRecords,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
